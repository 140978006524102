import React from 'react';
import cartIcon from '../images/cart.svg';

interface LandingServiceProps {
    header: string;
    message: string;
    IconComponent: JSX.Element
}

const LandingService: React.FC<LandingServiceProps> = ({ header, message, IconComponent }) => {
    return (
        <div className="p-1 text-center">
            <div className="flex items-center mb-1 justify-center h-16">
                {IconComponent}
            </div>
            <h3 className="font-bold text-2xl mb-2">{header}</h3>
            <p className="text-l pb-1">{message}</p>
        </div>
    )
}

export default LandingService;